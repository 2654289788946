var IntersectionObserverHandler = /** @class */ (function () {
    function IntersectionObserverHandler(props) {
        this.intersectionObserver = undefined;
        this.observe = this.observe.bind(this);
        this.unObserve = this.unObserve.bind(this);
        if ('IntersectionObserver' in window) {
            this.callBackHandler = props.callBackHandler;
            this.intersectionObserver = new IntersectionObserver(this.callBackHandler);
        }
    }
    /**
     * Observes a list of elements
     */
    IntersectionObserverHandler.prototype.observe = function (elementList) {
        var _this = this;
        elementList.forEach(function (element) {
            _this.intersectionObserver.observe(element);
        });
    };
    /**
     * Unobserves a list of elements
     */
    IntersectionObserverHandler.prototype.unObserve = function (elementList) {
        var _this = this;
        elementList.forEach(function (element) {
            _this.intersectionObserver.unobserve(element);
        });
    };
    return IntersectionObserverHandler;
}());
export { IntersectionObserverHandler };
export default IntersectionObserverHandler;
