var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Splide } from "@splidejs/splide";
export var CarouselVariant;
(function (CarouselVariant) {
    CarouselVariant["STANDARD"] = "standard";
    CarouselVariant["DENSE"] = "dense";
    CarouselVariant["HERO"] = "hero";
    CarouselVariant["SINGLE"] = "single";
    CarouselVariant["DYNAMIC"] = "dynamic";
})(CarouselVariant || (CarouselVariant = {}));
/**
 * Manages progressively enhancing lists to carousels
 */
var CarouselHandler = /** @class */ (function () {
    function CarouselHandler(props) {
        this.items = [];
        this.wrapper = props.wrapper;
        this.variantId = props.variantId;
        this.options = props.options || {
            type: "slide",
            speed: 1000,
            perPage: 3,
            perMove: 1,
            pagination: false,
            gap: "16px",
            autoplay: false,
            lazyLoad: false,
            role: "group"
        };
        this.classNames = props.classNames || {
            list: 'splide__list',
            navigationArrows: 'splide__arrows',
            paginationData: 'splide__pagination-data',
            controls: 'splide__controls',
        };
        this.hwtCarouselId = props.hwtCarouselId;
        this.locationId = props.locationId;
        this.locationType = props.locationType;
        this.amountToDisplay = props.amountToDisplay;
        this.departureDate = props.departureDate;
        this.departureDateType = props.departureDateType;
        this.departurePoints = props.departurePoints;
        this.boards = props.boards;
        this.ratings = props.ratings;
        this.duration = props.duration;
        this.init();
    }
    CarouselHandler.prototype.toggleNav = function (perPage) {
        var _a, _b, _c, _d;
        var shouldRenderNav = this.items.length > perPage;
        if (this.navigationArrows) {
            if (shouldRenderNav) {
                this.navigationArrows.removeAttribute('hidden');
                if (this.variantId !== CarouselVariant.HERO) {
                    (_a = this.paginationData) === null || _a === void 0 ? void 0 : _a.removeAttribute('hidden');
                    this.wrapper.style.paddingBottom = '60px';
                }
                if (this.variantId === CarouselVariant.HERO) {
                    (_b = this.controls) === null || _b === void 0 ? void 0 : _b.removeAttribute('hidden');
                }
            }
            else {
                this.navigationArrows.setAttribute('hidden', 'hidden');
                if (this.variantId !== CarouselVariant.HERO) {
                    (_c = this.paginationData) === null || _c === void 0 ? void 0 : _c.setAttribute('hidden', 'hidden');
                    this.wrapper.style.paddingBottom = '';
                }
                if (this.variantId === CarouselVariant.HERO) {
                    (_d = this.controls) === null || _d === void 0 ? void 0 : _d.setAttribute('hidden', 'hidden');
                }
            }
        }
    };
    CarouselHandler.prototype.setPaginationData = function (_a) {
        var activeSlideIndex = _a.activeSlideIndex;
        var itemCount = this.items.length;
        var startItem = activeSlideIndex + 1;
        var currentPerPage = this.instance ? this.instance.options.perPage : this.options.perPage;
        var endItem = startItem + (currentPerPage - 1);
        if (currentPerPage === 1) {
            this.paginationData.innerText = "".concat(startItem, " of ").concat(itemCount);
        }
        else {
            this.paginationData.innerText = "".concat(startItem, "-").concat(endItem, " of ").concat(itemCount);
        }
    };
    CarouselHandler.prototype.getVariantId = function () {
        return this.variantId;
    };
    CarouselHandler.prototype.init = function () {
        var _this = this;
        var _a, _b;
        // Dynamic carousel variant is for HWT
        if (this.variantId == CarouselVariant.DYNAMIC) {
            var locationString = void 0;
            switch (this.locationType) {
                case "Destination":
                    locationString = "destinations";
                    break;
                case "Region":
                    locationString = "regions";
                    break;
                case "Resort":
                    locationString = "resorts";
                    break;
                case "Accommodation":
                    locationString = "accommodation_id";
                    break;
                default:
                    locationString = "";
                    break;
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
            };
            // create querystring base with mandatory filters
            // let queryString: string = `?locationType=${locationString}&locationId=${this.locationId}&amountToDisplay=${this.amountToDisplay}`;
            var queryString = new URLSearchParams({
                "locationType": locationString,
                "locationID": this.locationId.toString(),
                "amountToDisplay": this.amountToDisplay
            });
            // if filters have been given values, append their respective query to the querystring
            if (this.departureDate != "unset") {
                queryString.append("departureDate", this.departureDate);
            }
            if (this.departureDateType != "unset") {
                queryString.append("departureDateType", this.departureDateType);
            }
            // cast departurePoints to an array, iterate through array to create query for querystring
            if (this.departurePoints != "unset" && this.departurePoints != undefined) {
                var departurePoints = this.departurePoints.split(",");
                var count_1 = 0;
                var points_1 = "";
                departurePoints.forEach(function (point) {
                    points_1 += "".concat(count_1 > 0 ? "," : "", "\\\"").concat(point, "\\\"");
                    count_1++;
                });
                queryString.append("departurePoints", "[".concat(points_1, "]"));
            }
            if (this.boards != "unset") {
                queryString.append("boards", this.boards);
            }
            if (this.ratings != "unset") {
                queryString.append("ratings", this.ratings);
            }
            if (this.duration != "unset") {
                queryString.append("duration", this.duration);
            }
            // get offers by using our own intermediatary API route to hide our API key.
            fetch("/umbraco/api/hwt/getoffers?".concat(queryString.toString()), requestOptions)
                .then(function (response) { return response.text(); })
                .then(function (result) {
                ///////////////////////////
                // FILL IN CAROUSEL DATA //
                ///////////////////////////
                // Get the result of the GraphQL query
                var jsonResult = JSON.parse(result)["data"]["offers"]["result"];
                // remove carousel if no results
                if (jsonResult.length < 1) {
                    _this.wrapper.parentElement.parentElement.remove();
                }
                else {
                    // Create list of indexes to remove from the carousel - this means only having to call "remove()" once
                    var indexesToRemove = [];
                    if (jsonResult.length < _this.items.length) {
                        for (var i = jsonResult.length; i < _this.items.length; i++) {
                            indexesToRemove.push(i);
                        }
                    }
                    // Get carousel's parent element with ID set from Umbraco
                    var carouselWrapper = document.querySelector("#".concat(_this.hwtCarouselId));
                    // For each entry in result find carousel item of same index and replace the content
                    for (var i = 0; i < _this.items.length; i++) {
                        try {
                            var carouselId = carouselWrapper.id;
                            // use carousel ID to get the IDs of the individual slide at index i
                            var carouselItemClass = "#".concat(carouselId, "-slide").concat(((i + 1).toString().length == 1) ? "0" + (i + 1).toString() : (i + 1).toString());
                            var carouselItem = document.querySelector(carouselItemClass);
                            ///////////////////////////////////////
                            // REPLACE SKELETON WITH API CONTENT //
                            ///////////////////////////////////////
                            // get and populate image element
                            var imageElement = carouselItem.querySelector("#cardImage>img");
                            imageElement.src = "https://assets.holidaywebtech.co.uk/".concat(jsonResult[i]["accommodation"]["images"][0]["url"]);
                            imageElement.parentElement.classList.remove("skeleton-pulse");
                            imageElement.classList.remove("hidden");
                            // get and populate title element
                            var breadcrumbsElement = carouselItem.querySelector("#cardBreadcrumbs");
                            var hwtResort = jsonResult[i]["accommodation"]["resort"];
                            var hwtDestination = hwtResort["regions"][0]["destinations"][0]["name"];
                            breadcrumbsElement.innerText = "".concat(hwtDestination, " \u2022 ").concat(hwtResort["name"]);
                            breadcrumbsElement.classList.remove("skeleton-pulse");
                            // get and populate title element
                            var titleElement = carouselItem.querySelector("#cardTitle");
                            titleElement.innerText = "".concat(jsonResult[i]["accommodation"]["name"]);
                            titleElement.classList.remove("skeleton-pulse");
                            // get and populate the rating element
                            var starElements = carouselItem.querySelectorAll(".skeleton-pulse-star");
                            for (var j = 0; j < starElements.length; j++) {
                                if (j < jsonResult[i]["accommodation"]["rating"]) {
                                    starElements[j].classList.replace("skeleton-pulse-star", "fill-selective-yellow");
                                }
                                else {
                                    starElements[j].classList.replace("skeleton-pulse-star", "fill-black/[0.4]");
                                }
                            }
                            // get and populate duration element
                            var durationElement = carouselItem.querySelector("#cardDuration");
                            durationElement.innerHTML = "<span style=\"color:#fbba00;\">\u2713</span> ".concat(jsonResult[i]["duration"]);
                            // insert the middle text between duration and departure date
                            var middleText = carouselItem.querySelector("#cardNightsText");
                            middleText.innerText = "Nights |";
                            // get and populate date element
                            var dateElement = carouselItem.querySelector("#cardDate");
                            dateElement.innerText = "".concat(_this.formatCardDate(jsonResult[i]["flights"][0]["departing"]));
                            // same parent element as duration
                            dateElement.parentElement.classList.remove("skeleton-pulse");
                            // get and populate board element
                            var boardElement = carouselItem.querySelector("#cardBoard");
                            boardElement.innerHTML = "<span style=\"color:#fbba00;\">\u2713</span> ".concat(_this.getBoardString(jsonResult[i]["rooms"][0]["board"]));
                            boardElement.classList.remove("skeleton-pulse");
                            // get and populate airport element
                            var airportElement = carouselItem.querySelector("#cardAirport");
                            airportElement.innerHTML = "<span style=\"color:#fbba00;\">\u2713</span> ".concat(jsonResult[i]["flights"][0]["departure_airport"]["name"]);
                            airportElement.classList.remove("skeleton-pulse");
                            // get and populate price element
                            var priceElement = carouselItem.querySelector("#cardPrice");
                            priceElement.innerText = "".concat(Math.round(jsonResult[i]["price_per_person"]));
                            priceElement.parentElement.parentElement.classList.remove("skeleton-pulse-price");
                            priceElement.parentElement.parentElement.classList.remove("flex-grow");
                            priceElement.parentElement.classList.remove("hidden");
                            var sisterText = priceElement.parentElement.parentElement.children[0];
                            sisterText.classList.remove("hidden");
                            //get and populate button link
                            var linkElement = carouselItem.querySelector("#cardCTA");
                            var fullCardLinkElement = carouselItem.querySelector("#hwtFullCardLink");
                            var accommodation_id = jsonResult[i]["accommodation"]["id"];
                            var accommodation = jsonResult[i]["accommodation"]["name"];
                            var resort = jsonResult[i]["accommodation"]["resort"]["name"];
                            var region = jsonResult[i]["accommodation"]["resort"]["regions"][0]["name"];
                            var destination = jsonResult[i]["accommodation"]["resort"]["regions"][0]["destinations"][0]["name"];
                            // let landingPageQueries = `?`;
                            var landingPageURL = "https://booking.haystravel.co.uk/flight-and-hotel/".concat(destination, "/").concat(region, "/").concat(resort, "/").concat(accommodation, "/").concat(accommodation_id, "?duration=").concat(jsonResult[i]["duration"], "&departingType=").concat(_this.departureDateType == "unset" ? "1" : _this.departureDateType, "&type=0&departurePoints=").concat(jsonResult[i]["flights"][0]["departure_airport"]["code"]);
                            var departing = jsonResult[i]["flights"][0]["departing"].substring(0, 10);
                            landingPageURL += "&departing=".concat(departing);
                            linkElement.href = landingPageURL;
                            linkElement.classList.remove("hidden");
                            fullCardLinkElement.href = landingPageURL;
                            // remove empty carousel items
                            _this.instance.remove(indexesToRemove);
                            // removing the empty carousel items refreshes the carousel
                            // this removes some inline styling that makes the cards and navigation display properly
                            // the 4 lines below re-add these styles
                            _this.list.style.display = "flex";
                            _this.list.style.flexDirection = "row";
                            _this.list.style.gap = "0";
                            carouselWrapper.style.paddingBottom = "80px";
                            // update the navigation to show correct number of items
                            _this.items.length = jsonResult.length;
                            _this.setPaginationData({ activeSlideIndex: 0 });
                            // if theres only 1 page of items then this function will remove the navigation
                            _this.toggleNav(_this.options.perPage);
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }
                }
            })
                .catch(function (error) { return console.error(error); });
        }
        //////////////
        this.navigationArrows = this.wrapper.querySelector(".".concat(this.classNames.navigationArrows));
        this.paginationData = (_a = this.wrapper) === null || _a === void 0 ? void 0 : _a.querySelector(".".concat(this.classNames.paginationData));
        this.controls = (_b = this.wrapper) === null || _b === void 0 ? void 0 : _b.querySelector(".".concat(this.classNames.controls));
        this.list = this.wrapper.querySelector(".".concat(this.classNames.list));
        this.list.style.display = "flex";
        this.list.style.flexDirection = "row";
        this.list.style.gap = "0px";
        if (this.list && this.list.children) {
            this.items = Array.from(this.list.children);
            if (this.items.length) {
                if (this.paginationData) {
                    this.setPaginationData({ activeSlideIndex: 0 });
                }
                this.toggleNav(this.options.perPage);
                this.instance = new Splide(this.wrapper, this.options);
                this.instance.mount();
                this.instance.on('moved', function (newIndex) {
                    if (_this.paginationData) {
                        _this.setPaginationData({ activeSlideIndex: newIndex });
                    }
                });
            }
        }
    };
    CarouselHandler.prototype.updateOptions = function (options) {
        if (this.instance) {
            this.instance.options = __assign(__assign({}, this.instance.options), options);
            if (this.options.perPage !== options.perPage) {
                this.instance.go(0);
                if (this.paginationData) {
                    this.setPaginationData({ activeSlideIndex: 0 });
                }
            }
        }
        this.toggleNav(options.perPage);
    };
    CarouselHandler.prototype.refresh = function () {
        var _a;
        (_a = this.instance) === null || _a === void 0 ? void 0 : _a.refresh();
    };
    CarouselHandler.prototype.formatCardDate = function (dateString) {
        var date = new Date(dateString);
        var day = date.getDate();
        var month = date.toLocaleString('en-US', { month: 'long' });
        var year = date.getFullYear();
        var formattedDate = "".concat(day).concat(getDateSuffix(day), " ").concat(month, " ").concat(year);
        return formattedDate;
        function getDateSuffix(day) {
            var lastDigit = day % 10;
            if (lastDigit === 1 && day !== 11) {
                return "st";
            }
            else if (lastDigit === 2 && day !== 12) {
                return "nd";
            }
            else if (lastDigit === 3 && day !== 13) {
                return "rd";
            }
            else {
                return "th";
            }
        }
    };
    CarouselHandler.prototype.getBoardString = function (boardID) {
        var boardStrings = [
            "Room Only",
            "Self Catering",
            "Bed & Breakfast",
            "Half Board",
            "Full Board",
            "All Inclusive",
        ];
        return boardStrings[boardID];
    };
    return CarouselHandler;
}());
export { CarouselHandler };
export default CarouselHandler;
