var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { SearchAndBookHandler } from "@modules/search-and-book";
import { MobileMenuHandler } from '@modules/mobile-menu';
import { mediaQueries, gaAffiliation } from "./constants";
import { CarouselVariant } from "@modules/carousel";
import { AdManager } from "@modules/ad-manager";
import { DataLayerEventHandler } from "./modules/data-layer";
import { required as requiredValidator } from "./helpers/validators/required";
import { email as emailValidator } from "./helpers/validators/email";
import GeneralPromoBlockGATrackingHandler from "./modules/ga-tracking/generalPromoBlock";
/**
 * Media queries
 */
var mobileMediaQuery = window === null || window === void 0 ? void 0 : window.matchMedia(mediaQueries.SM);
var tabletMediaQuery = window === null || window === void 0 ? void 0 : window.matchMedia(mediaQueries.MD);
var desktopMediaQuery = window === null || window === void 0 ? void 0 : window.matchMedia(mediaQueries.LG);
var largeDesktopMediaQuery = window === null || window === void 0 ? void 0 : window.matchMedia(mediaQueries.XL);
/**
 * Data layer events handler
 */
var dataLayer = new DataLayerEventHandler({});
/**
 * Search and book
 */
var searchAndBookSubmitHandler = function (activeTabName, values) {
    var event = {
        event: "searchSubmitted",
        searchBoxDepartureAirport: values['DEPARTURE AIRPORT'] || '',
        searchBoxDestination: values['DESTINATION'] || '',
        searchBoxDuration: values['DURATION'] || '',
        searchBoxOption: activeTabName,
        searchBoxPassengers: values['PASSENGERS'] || '',
        searchBoxSelectedDate: values['DATE'] || '',
        searchBoxSubmitted: ""
    };
    dataLayer.pushEventToDataLayer(event);
};
var searchAndBookHandler = new SearchAndBookHandler({ submitHandler: searchAndBookSubmitHandler });
/**
 * Platform 195 Ad handler
 */
var adManager = new AdManager();
adManager.initAllAdBlocks();
var adCtas = Array.from(document.querySelectorAll(".js-ad-click-handler"));
adCtas.forEach(function (adCta) {
    adCta.addEventListener('click', function () {
        var flightId = adCta.getAttribute('data-ad-flight-id');
        adManager.adClicked(flightId);
    });
});
/**
 * Carousels
 */
var standardCarouselColumnGap = '16px';
var standardCarouselNoColumnGap = '0px';
var carouselVariantsSettings = (_a = {},
    _a[CarouselVariant.STANDARD] = [
        {
            minWidth: 0,
            perPage: 1,
            gap: standardCarouselNoColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
        {
            minWidth: 768,
            perPage: 2,
            gap: standardCarouselColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
        {
            minWidth: 1024,
            perPage: 3,
            gap: standardCarouselColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
    ],
    _a[CarouselVariant.DENSE] = [
        {
            minWidth: 0,
            perPage: 1,
            gap: standardCarouselNoColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
        {
            minWidth: 768,
            perPage: 2,
            gap: standardCarouselColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
        {
            minWidth: 1024,
            perPage: 4,
            gap: standardCarouselColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
    ],
    _a[CarouselVariant.HERO] = [
        {
            minWidth: 0,
            perPage: 1,
            gap: standardCarouselNoColumnGap,
            pagination: true,
            type: "loop",
            autoplay: true,
            lazyLoad: 'sequential'
        }
    ],
    _a[CarouselVariant.SINGLE] = [
        {
            minWidth: 0,
            perPage: 1,
            gap: standardCarouselNoColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        }
    ],
    _a[CarouselVariant.DYNAMIC] = [
        {
            minWidth: 0,
            perPage: 1,
            gap: standardCarouselNoColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
        {
            minWidth: 768,
            perPage: 2,
            gap: standardCarouselColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
        {
            minWidth: 1024,
            perPage: 3,
            gap: standardCarouselColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
        {
            minWidth: 1440,
            perPage: 4,
            gap: standardCarouselColumnGap,
            pagination: false,
            type: "slide",
            autoplay: false,
            lazyLoad: false,
        },
    ],
    _a);
var carousels = Array.from(document.querySelectorAll(".js-carousel"));
var carouselHandlers = [];
var getCarouselVariantOptions = function (_a) {
    var windowWidth = _a.windowWidth, variantId = _a.variantId;
    var carouselVariantSettings = variantId && carouselVariantsSettings[variantId] ? carouselVariantsSettings[variantId] : carouselVariantsSettings[CarouselVariant.STANDARD];
    var options = {
        perPage: 1,
        gap: standardCarouselNoColumnGap,
        pagination: false,
        type: "slide",
        autoplay: false,
        lazyLoad: false,
    };
    for (var _i = 0, carouselVariantSettings_1 = carouselVariantSettings; _i < carouselVariantSettings_1.length; _i++) {
        var variant = carouselVariantSettings_1[_i];
        if (windowWidth >= variant.minWidth) {
            Object.assign(options, variant);
        }
    }
    return options;
};
var updateCarousels = function (windowWidth) {
    carouselHandlers.forEach(function (carouselHandler) {
        var variantId = carouselHandler.getVariantId();
        var options = getCarouselVariantOptions({ windowWidth: windowWidth, variantId: variantId });
        carouselHandler.updateOptions(options);
    });
};
var refreshCarousel = function () {
    document.addEventListener("toggle-dialog" /* dialog.toggle */, function () {
        carouselHandlers.forEach(function (carouselHandler) {
            var variantId = carouselHandler.getVariantId();
            if (variantId === CarouselVariant.HERO) {
                carouselHandler.refresh();
            }
        });
    });
};
if (carousels.length > 0) {
    import("./modules/carousel").then(function (_a) {
        var CarouselHandler = _a.default;
        carousels.forEach(function (carousel) {
            var variantId = carousel.dataset.variantId || CarouselVariant.STANDARD;
            var hwtCarouselId = carousel.dataset.hwtCarouselId || undefined;
            var locationId = carousel.dataset.locationId || undefined;
            var locationType = carousel.dataset.locationType || undefined;
            var amountToDisplay = carousel.dataset.amountToDisplay || undefined;
            var departureDate = carousel.dataset.departureDate || undefined;
            var departureDateType = carousel.dataset.departureDateType || undefined;
            var departurePoints = carousel.dataset.departurePoints || undefined;
            var boards = carousel.dataset.boards || undefined;
            var ratings = carousel.dataset.ratings || undefined;
            var instanceOptions = getCarouselVariantOptions({ windowWidth: window.innerWidth, variantId: variantId });
            var carouselHandler = new CarouselHandler({
                variantId: variantId,
                hwtCarouselId: hwtCarouselId,
                locationId: locationId,
                locationType: locationType,
                amountToDisplay: amountToDisplay,
                departureDate: departureDate,
                departureDateType: departureDateType,
                departurePoints: departurePoints,
                boards: boards,
                ratings: ratings,
                wrapper: carousel,
                options: Object.assign({}, {
                    type: "slide",
                    speed: 1000,
                    perPage: 1,
                    perMove: 1,
                    pagination: false,
                    gap: standardCarouselColumnGap,
                    role: "group"
                }, instanceOptions)
            });
            carouselHandlers.push(carouselHandler);
        });
        refreshCarousel();
    });
}
/**
 * Mobile menu
 * Lazy loads the mobile nav markup for performance before initialising the handler
 */
var mobileNavTrigger = document.getElementById('mobile-menu-button');
var mobileNavWrapper = document.getElementById('mobile-menu-wrapper');
var hasInitialisedMobileNav = false;
mobileNavTrigger.addEventListener('click', function () { return __awaiter(void 0, void 0, void 0, function () {
    var api, templateResponse, templateHTML, mobileMenuWrapper, activeContainer, mobileMenuBackButton, parentDialog, mobileMenuHandler_1, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!hasInitialisedMobileNav) return [3 /*break*/, 5];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                api = mobileNavTrigger.dataset['mobileContentApi'];
                return [4 /*yield*/, fetch(api)];
            case 2:
                templateResponse = _a.sent();
                return [4 /*yield*/, templateResponse.text()];
            case 3:
                templateHTML = _a.sent();
                mobileNavWrapper.innerHTML = templateHTML;
                mobileMenuWrapper = document.querySelector(".js-mobile-menu");
                activeContainer = mobileMenuWrapper.querySelector('.js-mobile-menu-container');
                mobileMenuBackButton = document.querySelector(".js-mobile-menu-back");
                parentDialog = mobileMenuWrapper.closest("dialog");
                mobileMenuHandler_1 = new MobileMenuHandler({
                    wrapper: mobileMenuWrapper,
                    activeContainer: activeContainer,
                    backButton: mobileMenuBackButton,
                    menuNodeSelector: '.js-mobile-menu-node'
                });
                parentDialog.addEventListener('close', function () { return mobileMenuHandler_1.reset(); });
                hasInitialisedMobileNav = true;
                return [3 /*break*/, 5];
            case 4:
                error_1 = _a.sent();
                console.log(error_1);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); });
var ptcFinderHandlers = [];
var loadPTCFinder = function (wrapper) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        import("./modules/ptc-finder").then(function (_a) {
            var PtcFinderHandler = _a.default;
            var ptcWrapper = document.querySelector(".js-ptc-finder");
            var searchFilter = ptcWrapper.querySelector('[data-ptc-filter]');
            var list = ptcWrapper.querySelector('[data-ptc-list]');
            var noResults = ptcWrapper.querySelector('[data-ptc-no-results]');
            ptcFinderHandlers.push(new PtcFinderHandler({
                wrapper: ptcWrapper,
                searchFilter: searchFilter,
                list: list,
                noResults: noResults
            }));
        });
        return [2 /*return*/];
    });
}); };
/**
 * PTC finder page
 * Loads the standalone page version of the PTC finder
 */
var ptcFinderPageWrapper = document.querySelector('.js-ptc-finder-page');
if (ptcFinderPageWrapper) {
    loadPTCFinder(ptcFinderPageWrapper);
}
/**
 * PTC finder slide in
 * Lazy loads the PTC markup for performance before initialising the handler
 */
var ptcFinderTriggers = Array.from(document.querySelectorAll('.js-ptc-button'));
ptcFinderTriggers.forEach(function (trigger) {
    var api = trigger.dataset['contentApi'];
    var ptcFinderWrapper = trigger.nextElementSibling.querySelector('.js-ptc-wrapper');
    var hasInitialisedPtc = false;
    if (ptcFinderWrapper) {
        import("./modules/ptc-finder").then(function (_a) {
            var PtcFinderHandler = _a.default;
            trigger.addEventListener('click', function (event) { return __awaiter(void 0, void 0, void 0, function () {
                var templateResponse, templateHTML, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!largeDesktopMediaQuery.matches) return [3 /*break*/, 6];
                            event.preventDefault();
                            if (!!hasInitialisedPtc) return [3 /*break*/, 6];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 5, , 6]);
                            return [4 /*yield*/, fetch(api)];
                        case 2:
                            templateResponse = _a.sent();
                            return [4 /*yield*/, templateResponse.text()];
                        case 3:
                            templateHTML = _a.sent();
                            ptcFinderWrapper.innerHTML = templateHTML;
                            return [4 /*yield*/, loadPTCFinder(ptcFinderWrapper)];
                        case 4:
                            _a.sent();
                            hasInitialisedPtc = true;
                            return [3 /*break*/, 6];
                        case 5:
                            error_2 = _a.sent();
                            console.log(error_2);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            }); });
        });
    }
});
/**
 * Dialogs
 */
var dialogs = Array.from(document.querySelectorAll(".js-dialog"));
var dialogHandlers = [];
var closeDialogs = function () { return dialogHandlers.forEach(function (dialogHandler) {
    dialogHandler.close();
}); };
var getShouldEnableTriggers = function (dialog) {
    var triggerSizes = dialog.dataset['triggerSizes'] ? dialog.dataset['triggerSizes'].split(',') : [];
    var shouldEnableTriggers = false;
    if (!triggerSizes.length) {
        return true;
    }
    triggerSizes.forEach(function (triggerSize) {
        var query = mediaQueries[triggerSize];
        if (window === null || window === void 0 ? void 0 : window.matchMedia(query).matches) {
            shouldEnableTriggers = true;
        }
    });
    return shouldEnableTriggers;
};
if (dialogs.length > 0) {
    import("./modules/dialog").then(function (_a) {
        var DialogHandler = _a.default;
        dialogs.forEach(function (dialog) {
            var _a, _b;
            var triggerElementAssociations = (_b = (_a = dialog.dataset['triggerIds']) === null || _a === void 0 ? void 0 : _a.split(',')) !== null && _b !== void 0 ? _b : [];
            var closeButton = dialog.querySelector('.js-close');
            var triggers = [];
            var shouldEnableTriggers = getShouldEnableTriggers(dialog);
            triggerElementAssociations.forEach(function (id) {
                var _a;
                var newTriggers = Array.from((_a = document.querySelectorAll("[data-dialog-trigger-id=".concat(id, "]"))) !== null && _a !== void 0 ? _a : []);
                newTriggers.forEach(function (trigger) { return triggers.push(trigger); });
            });
            dialogHandlers.push(new DialogHandler({
                wrapper: dialog,
                closeButton: closeButton,
                triggers: triggers,
                shouldEnableTriggers: shouldEnableTriggers,
                closeAction: closeDialogs
            }));
        });
    });
}
/**
 * Content tabs
 */
var contentTabs = Array.from(document.querySelectorAll(".js-content-tabs"));
var contentTabsHandlers = [];
if (contentTabs.length > 0) {
    import("./modules/tabs").then(function (_a) {
        var TabHandler = _a.default;
        contentTabs.forEach(function (contentTab) {
            var tabs = Array.from(contentTab.querySelectorAll(".js-content-tab"));
            var panels = Array.from(contentTab.querySelectorAll(".js-content-tab-panel"));
            if (tabs.length > 0 && panels.length > 0) {
                contentTabsHandlers.push(new TabHandler({
                    tabs: tabs,
                    panels: panels,
                }));
            }
        });
    });
}
/**
 * Link cards
 */
var linkCards = Array.from(document.querySelectorAll(".js-link-card"));
var linkCardsHandlers = [];
if (linkCards.length > 0) {
    import("./modules/link-card").then(function (_a) {
        var LinkCardHandler = _a.default;
        linkCards.forEach(function (linkCard) {
            var primaryHrefElement = linkCard.querySelector('.js-link-card-primary-href');
            linkCardsHandlers.push(new LinkCardHandler({
                wrapper: linkCard,
                primaryHrefElement: primaryHrefElement
            }));
        });
    });
}
/**
 * Branch finders
 */
var branchFinderHandlers = [];
var loadBranchFinder = function (wrapper) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        import("./modules/branch-finder").then(function (_a) {
            var BranchFinderHandler = _a.default;
            var locationFilter = wrapper.querySelector('[data-branch-location-filter]');
            var geoLocationFilter = wrapper.querySelector('[data-branch-geolocation-filter]');
            var sortOrderFilter = wrapper.querySelector('[data-branch-sort-order-filter]');
            var map = wrapper.querySelector('[data-branch-map]');
            var list = wrapper.querySelector('[data-branch-list]');
            var googleMapDefaultZoom = 10;
            branchFinderHandlers.push(new BranchFinderHandler({
                wrapper: wrapper,
                locationFilter: locationFilter,
                geoLocationFilter: geoLocationFilter,
                sortOrderFilter: sortOrderFilter,
                map: map,
                list: list,
                googleMapDefaultZoom: googleMapDefaultZoom
            }));
        });
        return [2 /*return*/];
    });
}); };
/**
 * Mapping
 */
window.handleGoogleMaps = function () { return __awaiter(void 0, void 0, void 0, function () {
    var branchFinderPageWrapper, branchFinderTriggers;
    return __generator(this, function (_a) {
        branchFinderPageWrapper = document.querySelector('.js-branch-finder-page');
        if (branchFinderPageWrapper) {
            loadBranchFinder(branchFinderPageWrapper);
        }
        branchFinderTriggers = Array.from(document.querySelectorAll('.js-branch-button'));
        branchFinderTriggers.forEach(function (trigger) {
            var api = trigger.dataset['contentApi'];
            var branchFinderWrapper = trigger.nextElementSibling.querySelector('.js-branch-wrapper');
            var hasInitialisedBranchFinder = false;
            trigger.addEventListener('click', function (event) { return __awaiter(void 0, void 0, void 0, function () {
                var templateResponse, templateHTML, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!largeDesktopMediaQuery.matches) return [3 /*break*/, 6];
                            event.preventDefault();
                            if (!!hasInitialisedBranchFinder) return [3 /*break*/, 6];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 5, , 6]);
                            return [4 /*yield*/, fetch(api)];
                        case 2:
                            templateResponse = _a.sent();
                            return [4 /*yield*/, templateResponse.text()];
                        case 3:
                            templateHTML = _a.sent();
                            branchFinderWrapper.innerHTML = templateHTML;
                            return [4 /*yield*/, loadBranchFinder(branchFinderWrapper)];
                        case 4:
                            _a.sent();
                            hasInitialisedBranchFinder = true;
                            return [3 /*break*/, 6];
                        case 5:
                            error_3 = _a.sent();
                            console.log(error_3);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            }); });
        });
        return [2 /*return*/];
    });
}); };
/**
 * Resize events
 */
var throttleDelay = 500;
var throttleTimeOut = false;
window.addEventListener('resize', function () {
    clearTimeout(throttleTimeOut);
    throttleTimeOut = setTimeout(function () {
        closeDialogs();
        dialogHandlers.forEach(function (dialogHandler) {
            var shouldEnableTriggers = getShouldEnableTriggers(dialogHandler.wrapper);
            dialogHandler.enableTriggers(shouldEnableTriggers);
        });
        updateCarousels(window.innerWidth);
    }, throttleDelay);
});
/**
 * Click & Collect
 */
var clickCollectPanel = document.querySelector('.js-click-collect-panel');
if (clickCollectPanel) {
    import("./modules/click-and-collect").then(function (_a) {
        var ClickCollectHandler = _a.default;
        var clickCollectHander = new ClickCollectHandler({ clickCollectPanel: clickCollectPanel });
    });
}
/**
 * Reload handler
 */
var reloadControls = Array.from(document.querySelectorAll(".js-reload"));
if (reloadControls.length > 0) {
    import("./modules/reload").then(function (_a) {
        var ReloadHandler = _a.default;
        reloadControls.forEach(function (reloadControl) {
            var focusTarget = reloadControl.querySelector(".js-reload-target");
            var handler = new ReloadHandler({
                wrapper: reloadControl,
                id: reloadControl.id,
                focusTarget: focusTarget,
            });
        });
    });
}
/**
 * Duplicate enquiry submission handler
 */
var enquiryForms = Array.from(document.querySelectorAll(".js-enquiry-form"));
if (enquiryForms.length > 0) {
    enquiryForms.forEach(function (enquiryForm) {
        var enquirySubmitBtn = enquiryForm.querySelector('input[type="submit"]');
        if (enquirySubmitBtn) {
            enquiryForm.addEventListener("submit", function () {
                enquirySubmitBtn.disabled = true;
                enquirySubmitBtn.classList.remove('bg-selective-yellow');
                enquirySubmitBtn.classList.remove('hover:bg-sunglow');
                enquirySubmitBtn.classList.remove('cursor-pointer');
                enquirySubmitBtn.classList.add('bg-loblolly');
                enquirySubmitBtn.classList.add('cursor-not-allowed');
            });
        }
    });
}
/*
 * Send newsletter signup events to GA
 */
var NewsletterSignUpQuerySelector = document.querySelector("#frmNewsletterSignUp");
if (NewsletterSignUpQuerySelector != null) {
    NewsletterSignUpQuerySelector.addEventListener("submit", function (event) {
        dataLayer.pushEventToDataLayer({
            event: "newsletter_sign_up",
            formId: "frmNewsletterSignUp",
        });
    });
}
/*
 * Send Enquiry Form events to GA
 */
var HolidayEnquiryFormQuerySelector = document.querySelector("#frmHolidayEnquirySubmit");
if (HolidayEnquiryFormQuerySelector != null) {
    HolidayEnquiryFormQuerySelector.addEventListener("submit", function (event) {
        var formData = new FormData(event.target);
        var title = formData.get('HolidayEnquiryForm.Title');
        var firstName = formData.get('HolidayEnquiryForm.FirstName');
        var lastName = formData.get('HolidayEnquiryForm.LastName');
        var email = formData.get('HolidayEnquiryForm.Email');
        var telephoneNumber = formData.get('HolidayEnquiryForm.Telephone');
        var preferredCallBackTimeNumber = formData.get('HolidayEnquiryForm.SelectedPreferredCallBackTime');
        var enquiry = formData.get('HolidayEnquiryForm.Enquiry');
        var formSuccessState = "undefined";
        if (requiredValidator(title) &&
            requiredValidator(firstName) &&
            requiredValidator(lastName) &&
            requiredValidator(email) &&
            emailValidator(email) &&
            requiredValidator(telephoneNumber) &&
            requiredValidator(preferredCallBackTimeNumber) &&
            requiredValidator(enquiry)) {
            formSuccessState = "Success";
        }
        else {
            formSuccessState = "Failure";
        }
        dataLayer.pushEventToDataLayer({
            event: "enquire_form_submit",
            form_state: formSuccessState,
        });
    });
}
/**
 * Send email branch events to GA
 */
var frmMessageBranchQuerySelector = document.querySelector("#frmMessageBranch");
if (frmMessageBranchQuerySelector != null) {
    frmMessageBranchQuerySelector.addEventListener("submit", function (event) {
        var formData = new FormData(event.target);
        var title = formData.get('Title');
        var firstName = formData.get('FirstName');
        var lastName = formData.get('LastName');
        var houseNumber = formData.get('HouseNumber');
        var postCode = formData.get('Postcode');
        var email = formData.get('Email');
        var telephoneNumber = formData.get('Telephone');
        var preferredCallBackTimeNumber = formData.get('SelectedPreferredCallBackTime');
        var enquiry = formData.get('Enquiry');
        var formSuccessState = "undefined";
        if (requiredValidator(title) &&
            requiredValidator(firstName) &&
            requiredValidator(lastName) &&
            requiredValidator(houseNumber) &&
            requiredValidator(postCode) &&
            requiredValidator(email) &&
            emailValidator(email) &&
            requiredValidator(telephoneNumber) &&
            requiredValidator(preferredCallBackTimeNumber) &&
            requiredValidator(enquiry)) {
            formSuccessState = "Success";
        }
        else {
            formSuccessState = "Failure";
        }
        dataLayer.pushEventToDataLayer({
            event: "branch_form_submit",
            formId: "frmMessageBranch",
            form_state: formSuccessState,
        });
    });
}
/**
 * Send Click And Collect Forex branch events to GA
 */
var frmClickAndCollectQuerySelector = document.querySelector("#frmClickAndCollect");
if (frmClickAndCollectQuerySelector != null) {
    frmClickAndCollectQuerySelector.addEventListener("submit", function (event) {
        var formData = new FormData(event.target);
        var currency = formData.get('Form.Currency');
        var gbpAmount = formData.get('Form.GbpAmount');
        var exchangeAmount = formData.get('Form.ExchangeAmount');
        var firstName = formData.get('Form.FirstName');
        var lastName = formData.get('Form.LastName');
        var email = formData.get('Form.Email');
        var telephoneNumber = formData.get('Form.Telephone');
        var collectionDate = formData.get('Form.CollectionDate');
        var selectedBranchId = formData.get('Form.SelectedBranchId');
        var buyBackGuarantee = formData.get('Form.BuyBackGuarantee');
        var todaysRate = frmClickAndCollectQuerySelector.querySelector('.js-today-rate').innerHTML;
        var orderRate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        var formSuccessState = "undefined";
        if (requiredValidator(gbpAmount) &&
            requiredValidator(exchangeAmount) &&
            requiredValidator(firstName) &&
            requiredValidator(lastName) &&
            requiredValidator(email) &&
            emailValidator(email) &&
            requiredValidator(telephoneNumber) &&
            requiredValidator(buyBackGuarantee)) {
            formSuccessState = "Success";
        }
        else {
            formSuccessState = "Failure";
        }
        dataLayer.pushEventToDataLayer({
            event: "VerifiedFormSubmit",
            currencyType: currency,
            gbpValue: gbpAmount,
            selectedCurrencyValue: exchangeAmount,
            exchangeRate: todaysRate,
            branch: selectedBranchId,
            orderDate: orderRate,
            collectionDate: collectionDate,
            buyBackGuarantee: buyBackGuarantee,
            form_state: formSuccessState,
        });
    });
}
/**
 * Send cruise enquiry events to GA
 */
var frmCruiseOnlineEnquirySelector = document.querySelector("#frmCruiseOnlineEnquiry");
if (frmCruiseOnlineEnquirySelector != null) {
    frmCruiseOnlineEnquirySelector.addEventListener("submit", function (event) {
        var formData = new FormData(event.target);
        var title = formData.get('CruiseEnquiryForm_Title');
        var firstName = formData.get('CruiseEnquiryForm_FirstName');
        var lastName = formData.get('CruiseEnquiryForm_LastName');
        var email = formData.get('CruiseEnquiryForm_Email');
        var telephoneNumber = formData.get('CruiseEnquiryForm_Telephone');
        var preferredCallBackTimeNumber = formData.get('CruiseEnquiryForm_SelectedPreferredCallBackTime');
        var enquiry = formData.get('CruiseEnquiryForm_Enquiry');
        var formSuccessState = "undefined";
        if (requiredValidator(title) &&
            requiredValidator(firstName) &&
            requiredValidator(lastName) &&
            requiredValidator(email) &&
            emailValidator(email) &&
            requiredValidator(telephoneNumber) &&
            requiredValidator(preferredCallBackTimeNumber) &&
            requiredValidator(enquiry)) {
            formSuccessState = "Success";
        }
        else {
            formSuccessState = "Failure";
        }
        dataLayer.pushEventToDataLayer({
            event: "enquire_form_submit",
            formId: "frmCruiseOnlineEnquiry",
            form_state: formSuccessState,
        });
    });
}
/**
 * Initialise GA tracking for promo block types
 */
var generalPromoElementList = Array.from(document.querySelectorAll(".js-ga-event"));
var generalPromoBlockGATracker = new GeneralPromoBlockGATrackingHandler({ elements: generalPromoElementList });
/**
 * Initialise GA tracking for holiday pages
 */
var holidayPage = document.querySelector(".js-holiday-page");
if (holidayPage) {
    var holidayPageDataLayerHandler = new DataLayerEventHandler({});
    var title = holidayPage.dataset['title'];
    var value = parseInt(holidayPage.dataset['price']);
    var data = {
        event: "view_item" /* GAEventTypes.VIEW_ITEM */,
        currency: 'GBP',
        value: value,
        ecommerce: {
            items: [
                {
                    id: title,
                    item_name: title,
                    affiliation: gaAffiliation,
                    index: 0
                }
            ]
        }
    };
    holidayPageDataLayerHandler.pushEventToDataLayer(data);
}
/**
 * Mobile Breadcrumbs
 */
var mobileBreadcrumbs = document.querySelector("#mobile-breadcrumbs");
// if mobile breadcrumbs exist and are visible
if (mobileBreadcrumbs && mobileBreadcrumbs.style.display != 'none') {
    // get the elipses span
    var showHiddenCrumbsBtn_1 = mobileBreadcrumbs.children[0].children[0];
    if (showHiddenCrumbsBtn_1.innerHTML == '···') {
        showHiddenCrumbsBtn_1.addEventListener("click", function () {
            var hiddenCrumbs = mobileBreadcrumbs.querySelectorAll('.hidden-breadcrumb');
            console.log(hiddenCrumbs);
            hiddenCrumbs.forEach(function (crumb) {
                showHiddenCrumbsBtn_1.parentElement.style.display = 'none';
                crumb.style.display = 'flex';
            });
        });
    }
}
/**
 * Excursions
 */
var branchField = document.querySelector(".umbraco-forms-field.branch.dropdown select");
console.log(branchField);
if (branchField) {
    var requestOptions = {
        method: "GET"
    };
    fetch("/umbraco/api/branches/getbrancheswithemails", requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (result) {
        result.forEach(function (branch) {
            var branchOption = document.createElement("option");
            branchOption.textContent = branch["Name"];
            branchOption.value = branch["Email"];
            branchField.appendChild(branchOption);
        });
    });
}
var excursionItems = Array.from(document.querySelectorAll(".js-excursion"));
var excursionHandlers = [];
import("./modules/excursions").then(function (_a) {
    var ExcursionHandler = _a.default;
    excursionItems.forEach(function (excursionItem) {
        var buttonId = excursionItem.dataset.buttonId;
        var excursionName = excursionItem.dataset.excursionName;
        var excursionHandler = new ExcursionHandler({
            buttonId: buttonId,
            excursionName: excursionName
        });
        excursionHandlers.push(excursionHandler);
    });
});
