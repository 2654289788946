export var required = function (value) {
    if (typeof value !== "undefined" && value !== null) {
        if (Array.isArray(value) && value.length === 0) {
            return false;
        }
        else if (typeof value === 'string') {
            if (value.trim && value.trim() === '') {
                return false;
            }
        }
        return true;
    }
    return false;
};
