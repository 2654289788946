var MobileMenuHandler = /** @class */ (function () {
    function MobileMenuHandler(props) {
        this.wrapper = undefined;
        this.backButton = undefined;
        this.activeContainer = undefined;
        this.menuNodeSelector = undefined;
        this.activeMenuId = undefined;
        this.rootMenu = undefined;
        this.rootMenuId = undefined;
        this.init = this.init.bind(this);
        this.reset = this.reset.bind(this);
        this.render = this.render.bind(this);
        this.showBackButton = this.showBackButton.bind(this);
        this.hideBackButton = this.hideBackButton.bind(this);
        this.wrapper = props.wrapper;
        this.backButton = props.backButton;
        this.activeContainer = props.activeContainer;
        this.menuNodeSelector = props.menuNodeSelector;
        this.init();
    }
    MobileMenuHandler.prototype.render = function (activeMenuId) {
        var isActiveMenuRootMenu = activeMenuId === this.rootMenuId;
        var activeMenu = isActiveMenuRootMenu ? this.rootMenu : this.rootMenu.querySelector('#' + activeMenuId);
        var clone = activeMenu.cloneNode(true);
        clone.classList.remove("hidden" /* styleUtilities.hidden */);
        this.wrapper.scrollTop = 0;
        this.activeMenuId = activeMenuId;
        this.activeContainer.innerHTML = '';
        this.activeContainer.append(clone);
        if (isActiveMenuRootMenu) {
            this.hideBackButton();
        }
        else {
            this.showBackButton();
        }
    };
    MobileMenuHandler.prototype.showBackButton = function () {
        this.backButton.classList.remove("hidden" /* styleUtilities.hidden */);
    };
    MobileMenuHandler.prototype.hideBackButton = function () {
        this.backButton.classList.add("hidden" /* styleUtilities.hidden */);
    };
    MobileMenuHandler.prototype.handleBackButtonClick = function () {
        var activeMenu = this.rootMenu.querySelector('#' + this.activeMenuId);
        var parentMenu = activeMenu.parentElement.closest(this.menuNodeSelector);
        var parentMenuId = parentMenu.id;
        this.render(parentMenuId);
    };
    MobileMenuHandler.prototype.reset = function () {
        this.render(this.rootMenuId);
    };
    MobileMenuHandler.prototype.init = function () {
        var _this = this;
        this.rootMenu = this.wrapper.querySelector(this.menuNodeSelector);
        this.rootMenuId = this.rootMenu.id;
        this.activeMenuId = this.rootMenuId;
        this.render(this.activeMenuId);
        this.backButton.addEventListener('click', function (event) {
            event.preventDefault();
            _this.handleBackButtonClick();
        });
        this.activeContainer.addEventListener('click', function (event) {
            var clickedElement = event.target;
            var anchorElement = clickedElement.closest('a');
            if (anchorElement) {
                var menuNodeId = anchorElement.dataset['nodeId'];
                var menuNode = _this.rootMenu.querySelector('#' + menuNodeId);
                if (menuNode) {
                    event.preventDefault();
                    _this.render(menuNodeId);
                }
            }
        });
    };
    return MobileMenuHandler;
}());
export { MobileMenuHandler };
export default MobileMenuHandler;
