var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defaultTimeOutMillis, fetchJSON } from "../../helpers/fetch-json";
var AdManager = /** @class */ (function () {
    function AdManager(options) {
        if (options === void 0) { options = { threshold: 0.5 }; }
        this.options = options;
        this.trackingData = [];
        this.viewableImpressionObserver = new IntersectionObserver(this.handleIntersection.bind(this), options);
    }
    AdManager.prototype.adClicked = function (Id) {
        return __awaiter(this, void 0, void 0, function () {
            var ad, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ad = this.trackingData.find(function (ad) { return ad.flightId === Id; });
                        if (!ad) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetchJSON(ad.clickUrl, {
                                method: "GET" /* requestMethods.GET */,
                                credentials: "include",
                                mode: 'cors',
                                keepalive: true,
                                headers: new Headers(),
                            }, defaultTimeOutMillis)];
                    case 2:
                        response = _a.sent();
                        if (response.meta.ok) {
                            window.location.href = ad.clickThroughUrl;
                        }
                        console.debug(response);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error fetching ad click data:", error_1);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        console.error("Ad not found for ID:", Id);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AdManager.prototype.adImpression = function (Id) {
        return __awaiter(this, void 0, void 0, function () {
            var ad, response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ad = this.trackingData.find(function (ad) { return ad.flightId === Id; });
                        if (!ad) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetchJSON(ad.impressionUrl, {
                                method: "GET" /* requestMethods.GET */,
                                credentials: "include",
                                headers: new Headers(),
                            }, defaultTimeOutMillis)];
                    case 2:
                        response = _a.sent();
                        console.debug(response);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.error("Error fetching ad impression data:", error_2);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        console.error("Ad not found for ID:", Id);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AdManager.prototype.adViewableImpression = function (Id) {
        return __awaiter(this, void 0, void 0, function () {
            var ad, response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ad = this.trackingData.find(function (ad) { return ad.flightId === Id; });
                        if (!ad) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetchJSON(ad.viewableImpressionUrl, {
                                method: "GET" /* requestMethods.GET */,
                                credentials: "include",
                                headers: new Headers(),
                            }, defaultTimeOutMillis)];
                    case 2:
                        response = _a.sent();
                        console.debug(response);
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        console.error("Error fetching ad viewable impression data:", error_3);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        console.error("Ad not found for ID:", Id);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AdManager.prototype.handleIntersection = function (entries) {
        var _this = this;
        entries.forEach(function (entry) {
            var target = entry.target;
            var el = _this.trackingData.find(function (ad) { return ad.flightId === target.dataset['flightId']; });
            if (el) {
                if (entry.isIntersecting) {
                    console.debug("is intersecting ad ".concat(el.flightId));
                    el.timeStart = entry.time;
                    if (!el.hasFiredViewableImpression) {
                        console.debug("sending viewable impression for ad ".concat(el.flightId));
                        _this.adViewableImpression(el.flightId);
                        el.hasFiredViewableImpression = true;
                    }
                    else {
                        console.debug("viewable impression already sent for ad ".concat(el.flightId));
                    }
                }
            }
        });
    };
    AdManager.prototype.configureTracking = function (adElement) {
        if (adElement) {
            var adTracking = {
                flightId: adElement.dataset['flightId'] || '',
                clickUrl: adElement.dataset['clickUrl'] || '',
                impressionUrl: adElement.dataset['impressionUrl'] || '',
                viewableImpressionUrl: adElement.dataset['viewableImpressionUrl'] || '',
                clickThroughUrl: adElement.dataset['clickThroughUrl'] || '',
                started: false,
                timeStart: 0,
                totalTime: 0,
                hasFiredViewableImpression: false
            };
            this.trackingData.push(adTracking);
        }
    };
    AdManager.prototype.initAdBlock = function (adBlock) {
        var flightId = adBlock.dataset['flightId'] || '';
        var adElement = document.querySelector("#uplift-ad-".concat(flightId));
        if (adElement) {
            this.configureTracking(adElement);
            this.viewableImpressionObserver.observe(adElement);
            this.adImpression(adElement.dataset['flightId'] || '');
        }
    };
    AdManager.prototype.initAllAdBlocks = function () {
        var _this = this;
        var adBlocks = document.querySelectorAll('.js-ad-block');
        adBlocks.forEach(function (adBlock) {
            _this.initAdBlock(adBlock);
        });
    };
    return AdManager;
}());
export { AdManager };
