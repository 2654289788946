import { gaAffiliation } from '../../../constants';
import { DataLayerEventHandler } from '../../data-layer';
import { IntersectionObserverHandler } from '../../intersection-observer';
/**
 * Manages GA tracking for General Promo Blocks
 */
var GeneralPromoBlockGATrackingHandler = /** @class */ (function () {
    function GeneralPromoBlockGATrackingHandler(props) {
        this.elements = [];
        this.pushElementEventToGA = this.pushElementEventToGA.bind(this);
        this.handleClicks = this.handleClicks.bind(this);
        this.handleIntersections = this.handleIntersections.bind(this);
        this.elements = props.elements || [];
        this.dataLayerHandler = new DataLayerEventHandler({});
        this.intersectionObserverHandler = new IntersectionObserverHandler({ callBackHandler: this.handleIntersections });
        this.intersectionObserverHandler.observe(this.elements);
        this.handleClicks();
    }
    /**
     * Handles pushing a General Promo Block item interaction to GA
     */
    GeneralPromoBlockGATrackingHandler.prototype.pushElementEventToGA = function (event, element, childElement) {
        var _a;
        var headingElement = element.getElementsByTagName("h2")[0];
        var listId = element.id;
        var listName = (_a = headingElement === null || headingElement === void 0 ? void 0 : headingElement.innerText) !== null && _a !== void 0 ? _a : '';
        var productContext = childElement || element;
        var productsList = Array.from(productContext.querySelectorAll('.js-ga-product-id'));
        var allProducts = productsList.map(function (a, index) {
            var item_id = a.getAttribute("id");
            var item_name = a.innerHTML;
            var affiliation = gaAffiliation;
            return {
                item_id: item_id,
                item_name: item_name,
                affiliation: affiliation,
                index: index
            };
        });
        var payload = {
            event: event,
            item_list_name: listName,
            item_list_id: listId,
            ecommerce: { items: allProducts }
        };
        this.dataLayerHandler.pushEventToDataLayer(payload);
        // intentional log for debugging
        console.log(payload);
    };
    /**
     * Handles clicks on General Promo block child items
     */
    GeneralPromoBlockGATrackingHandler.prototype.handleClicks = function () {
        var _this = this;
        this.elements.forEach(function (element) {
            element.addEventListener('click', function (event) {
                var promoBlockCard = event.target.closest('.js-link-card');
                if (promoBlockCard) {
                    _this.pushElementEventToGA("select_item" /* GAEventTypes.SELECT_ITEM */, element, promoBlockCard);
                }
            });
        });
    };
    /**
     * Handles General Promo block groups entering viewport
     */
    GeneralPromoBlockGATrackingHandler.prototype.handleIntersections = function (entries) {
        var _this = this;
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                _this.pushElementEventToGA("view_item_list" /* GAEventTypes.VIEW_ITEM_LIST */, entry.target);
                _this.intersectionObserverHandler.unObserve([entry.target]);
            }
        });
    };
    return GeneralPromoBlockGATrackingHandler;
}());
export { GeneralPromoBlockGATrackingHandler };
export default GeneralPromoBlockGATrackingHandler;
