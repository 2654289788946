var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { mediaQueries } from "../../constants";
var SearchAndBookHandler = /** @class */ (function () {
    function SearchAndBookHandler(props) {
        this.searchIntent = false;
        this.init = this.init.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitHandler = props.submitHandler;
        this.init();
    }
    SearchAndBookHandler.prototype.init = function () {
        var templateRoot = document.getElementById("root") || null;
        var desktopRoot = document.querySelector(".js-root") || null;
        var mobileRoot = document.querySelector(".js-mobile-root") || null;
        var media = window === null || window === void 0 ? void 0 : window.matchMedia(mediaQueries['2LG']);
        var isDesktop = media === null || media === void 0 ? void 0 : media.matches;
        if (templateRoot || desktopRoot && templateRoot) {
            document.addEventListener("DOMContentLoaded", function (e) {
                if (mobileRoot) {
                    media.onchange = function (event) {
                        if (!event.matches) {
                            mobileRoot.appendChild(templateRoot);
                        }
                        else {
                            desktopRoot.appendChild(templateRoot);
                        }
                    };
                    if (isDesktop) {
                        if (desktopRoot) {
                            desktopRoot.appendChild(templateRoot);
                        }
                    }
                    else {
                        if (mobileRoot) {
                            mobileRoot.appendChild(templateRoot);
                        }
                    }
                }
                else {
                    desktopRoot.appendChild(templateRoot);
                }
            });
        }
        this.handleSubmit();
    };
    SearchAndBookHandler.prototype.validateForm = function () {
        // Gets all elements with the class input-field--value to validate input
        var inputFields = document.querySelectorAll('.input-field--value');
        // Validates content of specified field
        var validateInputField = function (fieldName, errorMessage, inputIndex) {
            var inputField = inputFields[inputIndex];
            var inputStyle = inputField;
            var removeError = document.querySelector("#".concat(fieldName, "Error"));
            var packageTab = document.querySelectorAll('.search-option');
            //check if search and book exists
            // 
            if ((packageTab[2] && packageTab[2].textContent === 'Package Holidays' && packageTab[2].classList.contains('search-option--active')) || packageTab.length == 0) {
                // Checks if the user has fulfilled a field
                if (inputField.textContent === 'Select Airport' ||
                    inputField.textContent === 'Select Destination' ||
                    inputField.textContent === 'Select Date' ||
                    inputField.textContent === 'Any Date') {
                    // Creates and appends error if one does not exist
                    if (!removeError) {
                        var newErrorMessage = document.createElement('p');
                        newErrorMessage.id = fieldName + 'Error';
                        newErrorMessage.style.color = '#fbba00';
                        newErrorMessage.textContent = errorMessage;
                        inputField.parentElement.parentElement.appendChild(newErrorMessage);
                        inputStyle.style.border = '1px solid red';
                    }
                }
                // Removes error text if no errors are present
                else if (removeError) {
                    removeError.remove();
                    inputStyle.style.border = 'none';
                    inputStyle.style.color = 'rgb(1, 68, 123)';
                }
            }
            else if (removeError) {
                removeError.remove();
                inputStyle.style.border = 'none';
                inputStyle.style.color = 'rgb(1, 68, 123)';
            }
        };
        // Passes params to the function
        validateInputField('airport', 'Please select an airport', 0);
        validateInputField('dest', 'Please select a destination', 1);
        validateInputField('date', 'Please select a date', 2);
        // Runs on first click of search button
        if (!this.searchIntent) {
            this.searchIntent = true;
        }
    };
    SearchAndBookHandler.prototype.handleSubmit = function () {
        var _this = this;
        var templateRoot = document.getElementById("root") || null;
        if (templateRoot) {
            templateRoot.addEventListener('click', function (event) { return __awaiter(_this, void 0, void 0, function () {
                var clickedElement, isSubmitButton, isSubmitText, isSubmitIcon, config, observer, tabsContainer, activeTab, activeTabName, formValues_1, formWrapper, inputFieldWrappers;
                return __generator(this, function (_a) {
                    clickedElement = event.target;
                    isSubmitButton = clickedElement.classList.toString() === 'button button--medium button--primary button--search';
                    isSubmitText = clickedElement.classList.toString() === 'button--label';
                    isSubmitIcon = clickedElement.classList.toString() === 'button--icon';
                    if (isSubmitButton || isSubmitText || isSubmitIcon) {
                        // Set up MutationObserver the first time the button is clicked
                        if (!this.searchIntent) {
                            config = {
                                attributes: false,
                                childList: false,
                                subtree: true,
                                characterData: true
                            };
                            observer = new MutationObserver(this.validateForm);
                            observer.observe(document.querySelector(".search-bar-container"), config);
                        }
                        // Calls function when screen size is above 1100px
                        if (window.matchMedia("(min-width: 1100px)").matches) {
                            this.validateForm();
                        }
                        tabsContainer = templateRoot.querySelector('.search-options');
                        if (tabsContainer) {
                            activeTab = tabsContainer.querySelector('.search-option--active');
                            activeTabName = activeTab.querySelector('p').innerText;
                            formValues_1 = {};
                            formWrapper = clickedElement.parentElement;
                            inputFieldWrappers = Array.from(formWrapper.querySelectorAll('.input-field-wrapper'));
                            inputFieldWrappers.forEach(function (inputFieldWrapper) {
                                var labelElement = inputFieldWrapper.querySelector('.input-field--title');
                                var valueElement = inputFieldWrapper.querySelector('.input-field--value .selected-value');
                                var dateElement = inputFieldWrapper.querySelector('.input-field--value .selected-date');
                                var label = labelElement === null || labelElement === void 0 ? void 0 : labelElement.innerText;
                                var value = valueElement === null || valueElement === void 0 ? void 0 : valueElement.innerText;
                                var date = dateElement === null || dateElement === void 0 ? void 0 : dateElement.innerText;
                                formValues_1[label] = value || date || '';
                            });
                            this.submitHandler(activeTabName, formValues_1);
                        }
                    }
                    return [2 /*return*/];
                });
            }); });
        }
    };
    return SearchAndBookHandler;
}());
export { SearchAndBookHandler };
export default SearchAndBookHandler;
