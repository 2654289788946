/**
 * Manages pushing events to GA data layer
 */
var DataLayerEventHandler = /** @class */ (function () {
    function DataLayerEventHandler(props) {
        this.pushEventToDataLayer = this.pushEventToDataLayer.bind(this);
    }
    /**
     * Handles pushing events to the data layer
     */
    DataLayerEventHandler.prototype.pushEventToDataLayer = function (event) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    };
    return DataLayerEventHandler;
}());
export { DataLayerEventHandler };
export default DataLayerEventHandler;
